<script>
import { eventBus } from '../../main';
export default {
  name: 'subscribe-event',
  data() {
    return {};
  },

  created() {
    this.eventNames.forEach(e => {
      eventBus.$on(e, this.EmitEvent);
    });
  },
  beforeDestroy() {
    this.eventNames.forEach(e => {
      eventBus.$off(e, this.EmitEvent);
    });
  },
  computed: {
    eventNames() {
      if (Array.isArray(this.events)) {
        return this.events;
      }
      return [this.events];
    },
  },
  methods: {
    EmitEvent(e) {
      this.$emit('action', e);
    },
  },
  render() {
    return this.$slots.default;
  },
  props: {
    events: {
      type: [String, Array],
      required: true,
    },
  },
};
</script>
